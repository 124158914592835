<template>
  <div class="app-list-selector">
    <div
      v-for="(list, index) in [leftListInformation, rightListInformation]"
      :key="'list-' + index"
      class="app-list-selector__list"
    >
      <slot :name="list.name + '-list-title'" />
      <div class="app-list-selector__list__body">
        <AppInput
          v-model="searchModels[list.name]"
          class="app-list-selector__list__body__search-bar"
          clearable
          hide-bottom-space
          :placeholder="$t('common.search.placeholder')"
          :format-numbers="false"
          :clear-icon="mdiClose"
        />
        <div class="app-list-selector__list__body__options">
          <div
            v-for="option in list.options"
            :key="`${option[optionValueKey]}`"
            :class="[
              'app-list-selector__list__body__options__item',
              {
                'app-list-selector__list__body__options__item--selected':
                  selectedOptionValuesToOptionsMap[`${option[optionValueKey]}`],
              },
            ]"
            @keydown.exact="handleOptionClick(option)"
            @click.exact="handleOptionClick(option)"
            @click.ctrl="handleOptionClick(option, true)"
            @click.meta="handleOptionClick(option, true)"
          >
            <span class="app-list-selector__list__body__options__item__label">
              {{ option[optionValueKey] }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="app-list-selector__buttons">
      <AppButton
        fit-content
        type="empty"
        @click="
          moveSelectedOptions(
            AppListSelectorListSide.LEFT,
            AppListSelectorListSide.RIGHT,
          )
        "
      >
        <AppIcon
          size="24px"
          class="app-list-selector__buttons__left-icon"
          :name="
            buttonsInformation[orientation][AppListSelectorListSide.LEFT].icon
          "
        />
      </AppButton>
      <AppButton
        fit-content
        type="empty"
        @click="
          moveSelectedOptions(
            AppListSelectorListSide.RIGHT,
            AppListSelectorListSide.LEFT,
          )
        "
      >
        <AppIcon
          size="24px"
          class="app-list-selector__buttons__right-icon"
          :name="
            buttonsInformation[orientation][AppListSelectorListSide.RIGHT].icon
          "
        />
      </AppButton>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import AppButton from '@/components/app/AppButton/AppButton.vue';
import AppIcon from '@/components/app/AppIcon/AppIcon.vue';
import AppInput from '@/components/app/AppInput/AppInput.vue';
import {
  mdiArrowDownThick,
  mdiArrowLeftThick,
  mdiArrowRightThick,
  mdiArrowUpThick,
  mdiClose,
} from '@quasar/extras/mdi-v6';
import { BLUE_500 } from '@/shared/constants/colors';
import { Orientation } from '@/shared/types/utils';
import {
  AppListSelectorListInformation,
  AppListSelectorListSide,
  AppListSelectorOption,
} from '@/shared/types/components';

export default defineComponent({
  name: 'AppListSelector',

  components: { AppButton, AppIcon, AppInput },

  props: {
    left: {
      type: Array as PropType<AppListSelectorOption[]>,
      required: true,
    },
    right: {
      type: Array as PropType<AppListSelectorOption[]>,
      required: true,
    },
    optionLabelKey: {
      type: String as PropType<keyof AppListSelectorOption>,
      default: 'label',
    },
    optionValueKey: {
      type: String as PropType<keyof AppListSelectorOption>,
      default: 'value',
    },
    orientation: {
      type: String as PropType<Orientation>,
      default: Orientation.HORIZONTAL,
    },
    width: {
      type: String,
      default: 'unset',
    },
    height: {
      type: String,
      default: '256px',
    },
  },

  emits: ['update:left', 'update:right'],

  data() {
    return {
      selectedOptions: [] as AppListSelectorOption[],
      searchModels: {
        [AppListSelectorListSide.LEFT]: '',
        [AppListSelectorListSide.RIGHT]: '',
      },
      buttonsInformation: {
        [Orientation.HORIZONTAL]: {
          [AppListSelectorListSide.LEFT]: {
            label: 'labels.common.moveRight',
            icon: mdiArrowRightThick,
          },
          [AppListSelectorListSide.RIGHT]: {
            label: 'labels.common.moveLeft',
            icon: mdiArrowLeftThick,
          },
        },
        [Orientation.VERTICAL]: {
          [AppListSelectorListSide.LEFT]: {
            label: 'labels.common.moveDown',
            icon: mdiArrowDownThick,
          },
          [AppListSelectorListSide.RIGHT]: {
            label: 'labels.common.moveUp',
            icon: mdiArrowUpThick,
          },
        },
      },
      mdiClose,
      BLUE_500,
      AppListSelectorListSide,
    };
  },

  computed: {
    leftListInformation(): AppListSelectorListInformation {
      return {
        options: this.left
          .filter((option) =>
            `${option[this.optionLabelKey]}`
              .toLowerCase()
              .includes(this.searchModels.left.toString().toLowerCase()),
          )
          .sort((a, b) =>
            `${a[this.optionLabelKey]}`.localeCompare(
              `${b[this.optionLabelKey]}`,
            ),
          ),
        name: AppListSelectorListSide.LEFT,
      };
    },

    rightListInformation(): AppListSelectorListInformation {
      return {
        options: this.right
          .filter((option) =>
            `${option[this.optionLabelKey]}`
              .toLowerCase()
              .includes(this.searchModels.right.toString().toLowerCase()),
          )
          .sort((a, b) =>
            `${a[this.optionLabelKey]}`.localeCompare(
              `${b[this.optionLabelKey]}`,
            ),
          ),

        name: AppListSelectorListSide.RIGHT,
      };
    },

    selectedOptionValuesToOptionsMap(): Record<string, AppListSelectorOption> {
      return this.selectedOptions.reduce(
        (
          acc: Record<string, AppListSelectorOption>,
          option: AppListSelectorOption,
        ) => {
          acc[`${option[this.optionValueKey]}`] = option;

          return acc;
        },
        {},
      );
    },

    mainComponentFlexDirection(): 'row' | 'column' {
      if (this.orientation === Orientation.HORIZONTAL) {
        return 'row';
      }

      return 'column';
    },

    buttonsFlexDirection(): 'row' | 'column' {
      if (this.orientation === Orientation.HORIZONTAL) {
        return 'column';
      }

      return 'row';
    },

    mainComponentAlignment(): 'center' | 'left' {
      if (this.orientation === Orientation.HORIZONTAL) {
        return 'center';
      }

      return 'left';
    },
  },

  methods: {
    handleOptionClick(option: AppListSelectorOption, multiple = false): void {
      if (
        this.selectedOptionValuesToOptionsMap[`${option[this.optionValueKey]}`]
      ) {
        this.selectedOptions = this.selectedOptions.filter(
          (o) => o[this.optionValueKey] !== option[this.optionValueKey],
        );
      } else if (multiple) {
        this.selectedOptions.push(option);
      } else {
        this.selectedOptions = [option];
      }
    },

    moveSelectedOptions(
      from: AppListSelectorListSide,
      to: AppListSelectorListSide,
    ): void {
      const alreadyMovedOptionValues = this[to].map(
        (option) => option[this.optionValueKey],
      );

      const optionsToMove = this.selectedOptions.filter(
        (option) =>
          !alreadyMovedOptionValues.includes(option[this.optionValueKey]),
      );

      const optionsToMoveValues = optionsToMove.map(
        (option) => option[this.optionValueKey],
      );

      this.$emit(
        `update:${from}`,
        this[from].filter(
          (option) =>
            !optionsToMoveValues.includes(option[this.optionValueKey]),
        ),
      );

      this.$emit(`update:${to}`, [...this[to], ...optionsToMove]);

      this.selectedOptions = [];
    },
  },
});
</script>

<style scoped lang="scss">
.app-list-selector {
  gap: 8px;
  display: flex;
  flex-wrap: wrap;
  align-items: v-bind(mainComponentAlignment);
  flex-direction: v-bind(mainComponentFlexDirection);
}

.app-list-selector__list {
  gap: 4px;
  display: flex;
  flex-direction: column;
}

.app-list-selector > :nth-child(1) {
  order: 1;
}

.app-list-selector > :nth-child(2) {
  order: 3;
}

.app-list-selector > :nth-child(3) {
  order: 2;
}

.app-list-selector__list__body {
  display: flex;
  overflow: hidden;
  border-radius: 8px;
  width: v-bind(width);
  height: v-bind(height);
  flex-direction: column;
  border: 1px solid $gray-400;
}

.app-list-selector__list__body__search-bar {
  padding: 8px;
}

.app-list-selector__list__body__options {
  overflow-y: scroll;
}

.app-list-selector__list__body__options__item {
  flex: 0 1;
  cursor: pointer;
  padding: 4px 8px;
  background: $white;
}

.app-list-selector__list__body__options__item:not(
    .app-list-selector__list__body__options__item--selected
  ):hover {
  background-color: $gray-50;
}

.app-list-selector__list__body__options__item--selected {
  background-color: $blue-50;
}

.app-list-selector__list__body__options__item__label {
  color: $gray-800;
}

.app-list-selector__buttons {
  gap: 8px;
  display: flex;
  width: fit-content;
  flex-direction: v-bind(buttonsFlexDirection);
}
</style>
