import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2da08b28"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "app-input__word-counter"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppErrorMessage = _resolveComponent("AppErrorMessage")!
  const _component_q_input = _resolveComponent("q-input")!

  return (_openBlock(), _createBlock(_component_q_input, _mergeProps({
    ref: "input",
    modelValue: _ctx.model,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model) = $event))
  }, _ctx.bind, {
    outlined: "",
    "data-testid": "app-input",
    "bottom-slots": "",
    "no-error-icon": "",
    dark: false,
    class: _ctx.appInputClass,
    style: _ctx.appInputStyle,
    error: _ctx.isError,
    "input-style": _ctx.inputStyle,
    onBlur: _ctx.trimSpaces
  }), _createSlots({
    default: _withCtx(() => [
      (_ctx.isTextarea)
        ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.wordsLeft), 1))
        : _createCommentVNode("", true)
    ]),
    _: 2
  }, [
    (!_ctx.hideBottomSpace)
      ? {
          name: "error",
          fn: _withCtx(() => [
            _renderSlot(_ctx.$slots, "error", {}, () => [
              _createVNode(_component_AppErrorMessage, { error: _ctx.error }, null, 8, ["error"])
            ], true)
          ]),
          key: "0"
        }
      : undefined,
    _renderList(_ctx.$slots, (_, slot) => {
      return {
        name: slot,
        fn: _withCtx(() => [
          _renderSlot(_ctx.$slots, slot, {}, undefined, true)
        ])
      }
    })
  ]), 1040, ["modelValue", "class", "style", "error", "input-style", "onBlur"]))
}