<template>
  <div class="app-label">
    <span
      data-testid="app-label__label"
      :class="[
        'app-label__label',
        `app-label__label--${size}`,
        { 'app-label__label--required': isRequired },
      ]"
    >
      {{ label }}
      <span v-if="isOptional" class="app-label__label--optional">
        {{ $t('labels.common.optional') }}
      </span>
    </span>
    <AppHint
      v-if="information"
      :hint="information"
      :max-tooltip-width="maxTooltipWidth"
    />
    <slot v-else name="trailing" />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { matInfo } from '@quasar/extras/material-icons';
import { AppLabelSize } from '@/shared/types/generic';
import AppHint from '../AppHint/AppHint.vue';

export default defineComponent({
  name: 'AppLabel',

  components: {
    AppHint,
  },

  props: {
    label: {
      type: String,
      required: true,
    },
    information: {
      type: String,
      default: undefined,
    },
    size: {
      type: String as PropType<AppLabelSize>,
      default: 'medium',
    },
    maxTooltipWidth: {
      type: String,
      default: '256px',
    },
    isRequired: Boolean,
    isOptional: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      matInfo,
    };
  },
});
</script>

<style scoped lang="scss">
.app-label {
  display: flex;
  align-items: center;
  gap: 4px;
}

.app-label__label {
  color: $gray-800;
  font-weight: 700;
}

.app-label__label--optional {
  color: $gray-500;
  font-weight: 400;
  line-height: 140%;
}

.app-label__label--medium {
  font-size: 14px;
  line-height: 150%;
}

.app-label__label--large {
  font-size: 16px;
  line-height: 130%;
}

.app-label__label--required:after {
  content: '*';
}
</style>
