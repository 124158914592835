<script setup lang="ts">
import AppInput from '@/components/app/AppInput/AppInput.vue';
import AppPasswordIcon from '@/components/app/AppPasswordIcon/AppPasswordIcon.vue';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

interface Props {
  error: string;
  placeholder?: string;
}

const { t } = useI18n();

const props = withDefaults(defineProps<Props>(), {
  placeholder: 'placeholders.login.password',
});

const model = defineModel<string>();

const passwordIsShown = ref<boolean>(false);

const inputType = computed<'text' | 'password'>(() => {
  if (passwordIsShown.value) {
    return 'text';
  }

  return 'password';
});

const isError = computed<boolean>(() => props.error.length > 0);

function togglePasswordVisibility(): void {
  passwordIsShown.value = !passwordIsShown.value;
}
</script>

<template>
  <AppInput
    v-model="model"
    :dense="false"
    :error="error"
    :type="inputType"
    :format-numbers="false"
    :placeholder="t(placeholder)"
  >
    <template #append>
      <AppPasswordIcon
        :password-has-errors="isError"
        :show-password="passwordIsShown"
        @click="togglePasswordVisibility"
      />
    </template>
  </AppInput>
</template>
