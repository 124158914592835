<template>
  <div class="app-header">
    <div class="app-header__leading">
      <slot />
    </div>
    <slot name="trailing" />
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AppHeader',
});
</script>

<style scoped lang="scss">
.app-header {
  display: flex;
  margin-bottom: 32px;
  min-height: 32px;
  justify-content: space-between;
}

.app-header__leading {
  display: flex;
  align-self: stretch;
  align-items: center;
}
</style>
