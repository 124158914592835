<template>
  <q-icon v-bind="$props" :class="appIconClass" />
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AppIcon',

  props: {
    name: { type: String, default: undefined },
    size: {
      type: String,
      default: undefined,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    appIconClass(): Array<Record<string, boolean>> {
      return [{ 'app-icon--rounded': this.rounded }];
    },
  },
});
</script>

<style scoped lang="scss">
.app-icon--rounded {
  border-radius: 8px;
  overflow: hidden;
}
</style>
