<template>
  <q-skeleton v-bind="$props" />
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AppLoading',
});
</script>

<style scoped lang="scss"></style>
