<script setup lang="ts">
import AppBadge from '@/components/app/AppBadge/AppBadge.vue';
import getStatusBadgeColor from '@/shared/helpers/getStatusBadgeColor/getStatusBadgeColor';
import { StatusStaticReference } from '@/shared/types/generic';
import { useI18n } from 'vue-i18n';

interface Props {
  status: StatusStaticReference;
}

defineProps<Props>();

const { t } = useI18n();
</script>

<template>
  <AppBadge
    height="32px"
    padding="4px 16px"
    class="app-table-status-column"
    :color="getStatusBadgeColor(status)"
  >
    {{ t(`enums.status.${status}`) }}
  </AppBadge>
</template>

<style lang="scss" scoped>
.app-table-status-column {
  font-size: 14px;
  line-height: 140%;
  border-radius: 8px;
}
</style>
