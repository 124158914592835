<template>
  <q-tabs v-bind="$attrs" class="app-tabs" data-testid="app-tabs">
    <template v-for="tab in tabs" :key="tab.to">
      <div class="app-tabs__tab" data-testid="app-tabs-tab">
        <AppSeparator
          v-if="tab.separator"
          vertical
          inset
          class="app-tabs__separator"
        />
        <q-route-tab
          :to="tab.to"
          :ripple="false"
          :label="tab.label"
          :exact="tab?.exact"
          no-caps
          data-testid="app-tabs-tab-route"
          :class="[{ 'q-tab--active': tab.active }]"
          @click="tab?.onClick"
        />
        <span
          v-if="tab?.count"
          class="app-tabs__tab__count"
          data-testid="app-tabs-tab-count"
        >
          {{ tab?.count }}
        </span>
      </div>
    </template>
  </q-tabs>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { AppTab } from '@/shared/types/components';
import AppSeparator from '../AppSeparator/AppSeparator.vue';

export default defineComponent({
  name: 'AppTabs',

  components: { AppSeparator },

  props: {
    tabs: {
      type: Array as PropType<AppTab[]>,
      default: () => [],
    },
  },
});
</script>

<style scoped lang="scss">
.app-tabs :deep(.q-tab__indicator),
.app-tabs :deep(.q-focus-helper) {
  display: none;
}

.app-tabs {
  min-height: 48px;
}

.app-tabs .q-tab {
  padding: unset;
  min-height: unset;
}

.app-tabs :deep(.q-tabs__content) {
  gap: 32px;
  justify-content: flex-start;
}

.app-tabs :deep(.q-tab__label) {
  opacity: 0.5;
  color: $blue-500;
  font-size: 20px;
  font-weight: 700;
  line-height: 130%;
}

.app-tabs :deep(.q-tab--active) .q-tab__label {
  opacity: 1;
}

.app-tabs__tab {
  gap: 8px;
  display: flex;
  align-items: center;
}

.app-tabs__tab__count {
  padding: 0 8px;
  margin-left: 0px;
  background: $blue-50;
  border-radius: 4px;
  font-size: 12px;
  color: $gray-800;
}

.app-tabs__separator {
  margin-right: 20px;
}
</style>
