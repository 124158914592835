<template>
  <AppButton
    dense
    :ripple="false"
    :icon="'img:' + showPasswordIcon"
    class="password-icon"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import AppButton from '@/components/app/AppButton/AppButton.vue';
import {
  passwordIcon,
  passwordIconClosed,
  passwordIconClosedError,
  passwordIconError,
} from '@/shared/constants/icons';

export default defineComponent({
  name: 'AppPasswordIcon',

  components: {
    AppButton,
  },

  props: {
    passwordHasErrors: { type: Boolean, default: false },
    showPassword: { type: Boolean, default: false },
  },

  computed: {
    showPasswordIcon(): string {
      if (this.passwordHasErrors) {
        return this.showPassword ? passwordIconClosedError : passwordIconError;
      }

      return this.showPassword ? passwordIconClosed : passwordIcon;
    },
  },
});
</script>

<style scoped lang="scss">
.password-icon {
  width: 24px;
  > :deep(.q-focus-helper) {
    display: none;
  }
}
</style>
