
import { GRAY_800 } from '@/shared/constants/colors';
import { defineComponent } from 'vue';

const __default__ = defineComponent({
  name: 'AppBadge',

  props: {
    color: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      default: '90px',
    },
    height: {
      type: String,
      default: '18px',
    },
    padding: {
      type: String,
      default: 'unset',
    },
    textColor: {
      type: String,
      default: GRAY_800,
    },
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "6a72ef7b": (_ctx.height),
  "4654b4f2": (_ctx.width),
  "f7fcaf86": (_ctx.padding),
  "93fb813c": (_ctx.textColor),
  "453db68f": (_ctx.color)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__