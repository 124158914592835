import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-207c4940"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "app-footer" }
const _hoisted_2 = { class: "app-footer-trademark" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", _hoisted_2, "© " + _toDisplayString(_ctx.currentYear) + " Körber", 1)
  ]))
}