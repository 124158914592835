<template>
  <q-select
    v-bind="$props"
    ref="qselect"
    v-model="model"
    :options="filteredOptions"
    :dropdown-icon="symRoundedKeyboardArrowDown"
    :input-debounce="inputDebounce"
    :style="style"
    :class="['app-select', `app-select--${size}`]"
    input-class="app-select__input"
    :popup-content-class="`app-select__popup-content ${popupContentClass}`"
    dense
    options-dense
    :error="isError"
    :clearable="false"
    borderless
    no-error-icon
    data-testid="app-select-input"
    @blur="handlerOnBlur"
    @popup-show="handleSelectPopupShow($el, '--appSelectMaxWidth')"
  >
    <template #append>
      <slot v-if="$slots.append" name="before-options" />
      <template v-else>
        <AppClearIcon v-if="showClearable" @click="handleClear" />
      </template>
    </template>
    <template v-if="showPlaceholder" #selected>
      <span
        key="placeholder"
        class="app-select__placeholder"
        data-testid="app-select-placeholder"
      >
        {{ placeholder }}
      </span>
    </template>
    <template v-else-if="filterLabel || multiple" #selected>
      <span
        key="filterLabel"
        class="app-select__label"
        data-testid="app-select-filter-label"
      >
        {{ filterLabel || $t('common.filters.options', model.length) }}
      </span>
    </template>
    <template v-else #selected-item="{ opt }">
      <slot v-if="$slots['selected-item']" name="selected-item" :opt="opt" />
      <span
        v-else
        class="app-select__label"
        data-testid="app-select-option-label"
      >
        {{ opt[optionLabel] || opt }}
      </span>
    </template>

    <template #before-options>
      <div
        v-if="isSearchShown"
        class="app-select__option__search-wrap"
        data-testid="app-select-option-search-wrap"
      >
        <AppSearch
          v-model="filter"
          class="app-select__option__search"
          select-search
        />
      </div>
      <slot v-if="$slots['before-options']" name="before-options" />
      <template v-else>
        <div
          v-if="showAllOption"
          class="app-select__options__select-all"
          data-testid="app-select-options-select-all"
          @click="handleSelectAll(false)"
          @keydown="handleSelectAll(false)"
        >
          <q-item :class="[optionClass, { 'q-item--active': allModel }]">
            <q-item-section v-if="showCheckbox" avatar>
              <q-checkbox
                v-model="allModel"
                toggle-order="ft"
                data-testid="app-select-checkbox"
                @click="handleSelectAll(true)"
              />
            </q-item-section>
            <q-item-section>
              <q-item-label data-testid="app-select-item-label">
                {{ $t('common.filters.all') }}
              </q-item-label>
            </q-item-section>
          </q-item>
        </div>
      </template>
    </template>

    <template #no-option>
      <div v-if="isSearchShown" class="app-select__option__search-wrap">
        <AppSearch
          v-model="filter"
          class="app-select__option__search"
          select-search
        />
      </div>
      <q-item
        :class="[optionClass]"
        class="q-item--dense app-select__option app-select__option--not-found"
      >
        <AppLoading v-if="isLoadingExternalSearch" width="460px" />

        <q-item-section
          v-if="!externalSearch || isShowEmptyStateExternalSearch"
        >
          <q-item-label data-testid="app-select-item-label">
            {{ filteredNoOptionLabel || $t('labels.common.noOptionFound') }}
          </q-item-label>
        </q-item-section>
      </q-item>
    </template>

    <template
      #option="{ index, itemProps, opt, label, selected, toggleOption }"
    >
      <slot
        v-if="$slots.option"
        :index="index"
        :item-props="itemProps"
        :opt="opt"
        :label="label"
        :selected="selected"
        :toggle-option="toggleOption"
        name="option"
        data-testid="app-select-slot-option"
      />
      <q-item
        v-else
        v-bind="itemProps"
        class="app-select__option"
        :disable="disableOptions && !selected"
        data-testid="app-select-item"
        @update:model-value="toggleOption(opt)"
      >
        <q-item-section v-if="showCheckbox" avatar>
          <q-checkbox
            :model-value="selected"
            data-testid="app-select-checkbox"
            :disable="disableOptions && !selected"
            @update:model-value="toggleOption(opt)"
          />
        </q-item-section>
        <q-item-section @update:model-value="toggleOption(opt)">
          <q-item-label data-testid="app-select-item-label">
            {{ label }}
          </q-item-label>
        </q-item-section>
      </q-item>
    </template>
    <template #error>
      <AppErrorMessage :error="error" />
    </template>
  </q-select>
</template>

<script lang="ts">
import { defineComponent, PropType, StyleValue } from 'vue';
import _ from 'lodash';
import { symRoundedKeyboardArrowDown } from '@quasar/extras/material-symbols-rounded';
import handleSelectPopupShow from '@/shared/helpers/handleSelectPopupShow/handleSelectPopupShow';
import { AppSelectSize } from '@/shared/types/components';
import { QSelect } from 'quasar';
import AppLoading from '@/components/app/AppLoading/AppLoading.vue';
import AppClearIcon from '../AppClearIcon/AppClearIcon.vue';
import AppErrorMessage from '../AppErrorMessage/AppErrorMessage.vue';
import AppSearch from '../AppSearch/AppSearch.vue';

export default defineComponent({
  name: 'AppSelect',

  components: { AppSearch, AppErrorMessage, AppClearIcon, AppLoading },

  props: {
    modelValue: {
      type: [Object, Array, String, Number, Boolean],
      default: undefined,
    },
    options: {
      type: [Array],
      default: () => [],
    },
    width: {
      type: String,
      default: undefined,
    },
    placeholder: {
      type: String,
      default: undefined,
    },
    filterLabel: {
      type: String,
      default: undefined,
    },
    optionClass: {
      type: String,
      default: undefined,
    },
    showSearch: {
      type: Boolean,
      default: undefined,
    },
    showAllOption: {
      type: Boolean,
      default: false,
    },
    showCheckbox: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String as PropType<AppSelectSize>,
      default: 'M',
    },
    optionLabel: {
      type: String,
      default: 'label',
    },
    optionValue: {
      type: String,
      default: 'value',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    inputDebounce: {
      type: Number,
      default: 0,
    },
    emitValue: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: undefined,
    },
    errorPadding: {
      type: Boolean,
      default: false,
    },
    popupContentClass: {
      type: String,
      default: '',
    },
    filteredNoOptionLabel: {
      type: String,
      default: '',
    },
    hideBottomSpace: {
      type: Boolean,
      default: false,
    },
    externalSearch: {
      type: Function || undefined,
      default: undefined,
    },
    isLoadingExternalSearch: {
      type: Boolean,
      default: false,
    },
    eventBlur: {
      type: Function || undefined,
      default: undefined,
    },
    maxSelected: {
      type: Number,
      default: 0,
    },
  },

  emits: ['update:modelValue', 'blurEvent'],

  data() {
    return {
      symRoundedKeyboardArrowDown,
      filter: '',
      allModel: false as boolean | null,
    };
  },

  computed: {
    model: {
      get() {
        return this.modelValue;
      },
      set(newValue: unknown) {
        this.$emit('update:modelValue', newValue);
      },
    },

    isShowEmptyStateExternalSearch(): boolean {
      return !!(
        this.externalSearch &&
        this.filter.length >= 3 &&
        !this.isLoadingExternalSearch
      );
    },

    filteredOptions() {
      if (this.isSearchShown && this.filter.length >= 3) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return this.options.filter((option: any) =>
          option[this.optionLabel]
            .toLowerCase()
            .includes(this.filter.toLowerCase()),
        );
      }
      return this.options;
    },

    showPlaceholder(): boolean {
      return (
        this.placeholder !== undefined &&
        (!this.model ||
          (_.isObject(this.model) && _.isEmpty(this.model)) ||
          (Array.isArray(this.model) && this.model.length === 0))
      );
    },

    style(): StyleValue {
      return {
        width: this.width,
      };
    },

    isError(): boolean | undefined {
      if ((this.error?.length || 0) > 0) {
        return true;
      }
      return this.errorPadding ? false : undefined;
    },

    isSearchShown(): boolean {
      if (this.showSearch === undefined) {
        return this.options.length >= 7;
      }

      return this.showSearch;
    },

    showClearable(): boolean {
      return (
        this.clearable &&
        !(!this.model || (Array.isArray(this.model) && this.model.length === 0))
      );
    },

    disableOptions(): boolean {
      return (
        !!this.maxSelected &&
        Array.isArray(this.model) &&
        this.model.length >= this.maxSelected
      );
    },
  },

  watch: {
    modelValue: {
      immediate: true,
      handler(newValue): void {
        if (newValue && newValue.length === 0) {
          this.allModel = false;
        } else if (newValue && newValue.length === this.options.length) {
          this.allModel = true;
        } else {
          this.allModel = null;
        }

        if (this.externalSearch) {
          this.filter = '';
        }
      },
    },

    filter: {
      handler(): void {
        if (this.externalSearch && this.filter.length > 3) {
          this.externalSearch(this.filter);
        }
      },
    },

    options: {
      handler(): void {
        if (this.allModel) {
          this.model =
            this.emitValue &&
            this.options &&
            typeof this.options[0] === 'object'
              ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
                this.options.map((el: any) => el[this.optionValue])
              : this.options;
        }
      },
    },
  },

  methods: {
    handleClear(e: Event): void {
      e.preventDefault();
      e.stopPropagation();
      this.$emit('update:modelValue', this.multiple ? [] : undefined);
    },

    handleSelectAll(checkboxClicked: boolean): void {
      if (
        (checkboxClicked && this.allModel) ||
        (!checkboxClicked && this.allModel === false)
      ) {
        this.$emit(
          'update:modelValue',
          this.emitValue && this.options && typeof this.options[0] === 'object'
            ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
              this.options.map((el: any) => el[this.optionValue])
            : this.options,
        );
      } else {
        this.$emit('update:modelValue', []);
      }

      if (!this.multiple) {
        this.hidePopup();
      }
    },

    hidePopup() {
      (this.$refs.qselect as QSelect).hidePopup();
    },

    handlerOnBlur(): void {
      if (this.eventBlur) {
        this.eventBlur(this.model);
      }
    },
    handleSelectPopupShow,
  },
});
</script>

<style scoped lang="scss">
.app-select :deep(.q-field__control) {
  border-radius: 4px;
  padding: 1px;
  border: 1px solid $gray-400;
  background-color: $white;
  min-height: 32px;
  box-sizing: border-box;
}

.app-select--M :deep(.q-field__native) {
  height: 44px;
}

.app-select--M :deep(.app-select__label),
.app-select--M :deep(.app-select__input),
.app-select--M :deep(.app-select__placeholder) {
  line-height: 34px;
}

.app-select :deep(.app-search .app-search__input) {
  flex-grow: 1;
}

.app-select:hover :deep(.q-field__control) {
  border: 1px solid $blue-400;
}

.app-select.q-field--error :deep(.q-field__control) {
  border: 1px solid $red-500;
}

.app-select.q-field--focused :deep(.q-field__control) {
  border: 1px solid $blue-500;
}

.app-select :deep(.q-field__native) {
  padding: 5px 0 5px 12px;
  align-items: flex-start;
}

.app-select :deep(.q-field__append) {
  height: unset;
  padding: 0 8px;
  color: $gray-800;
}

.app-select__placeholder {
  color: $gray-500;
}

.app-select__label,
.app-select__placeholder {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.app-select.q-field--disabled :deep(.q-field__control) {
  background-color: $gray-50;
  border-color: $gray-50;
}
</style>
