<template>
  <q-separator :class="computedClass" v-bind="$props" />
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AppSeparator',
  props: {
    vertical: {
      type: Boolean,
      default: false,
    },
    inset: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    computedClass(): string {
      let result = 'app-separator';
      if (this.vertical) {
        result += ' app-separator--vertical';
      }
      return result;
    },
  },
});
</script>

<style scoped lang="scss">
.app-separator {
  background-color: $gray-50;
}

.app-separator--vertical {
  background-color: $gray-100;
  margin-top: 5px;
  margin-bottom: 5px;
}
</style>
