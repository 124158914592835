<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import IconEdit from '@/assets/icons/icon-edit.vue';
import AppButton from '../AppButton/AppButton.vue';

interface Props {
  iconSize?: string;
}

withDefaults(defineProps<Props>(), { iconSize: '16px' });

const { t } = useI18n();
</script>

<template>
  <AppButton size="M" type="bordered" :label="t('common.edit')">
    <template #prepend>
      <IconEdit class="app-edit-button__icon" />
    </template>
  </AppButton>
</template>

<style scoped lang="scss">
.app-edit-button__icon {
  aspect-ratio: 1;
  width: v-bind(iconSize);
}
</style>
