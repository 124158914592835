import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, createSlots as _createSlots, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppIcon = _resolveComponent("AppIcon")!
  const _component_q_input = _resolveComponent("q-input")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
      'app-search',
      {
        'app-search__filled': !_ctx.isFilterEmpty,
        'app-search--select': _ctx.selectSearch,
      },
    ])
  }, [
    _createVNode(_component_q_input, {
      modelValue: _ctx.model,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model) = $event)),
      borderless: "",
      class: "app-search__input",
      debounce: "300",
      placeholder: _ctx.searchInputPlaceholder,
      "data-testid": "app-search",
      onFocus: _ctx.focusInput,
      onBlur: _ctx.blurInput
    }, _createSlots({ _: 2 }, [
      (!_ctx.selectSearch)
        ? {
            name: "prepend",
            fn: _withCtx(() => [
              (_ctx.isFontIcon(_ctx.appTableContentSearchIcon))
                ? (_openBlock(), _createBlock(_component_AppIcon, {
                    key: 0,
                    name: String(_ctx.appTableContentSearchIcon),
                    size: "20px",
                    class: _normalizeClass([
            'app-search__icon',
            { 'app-search__icon--black': _ctx.isInputFocused },
          ]),
                    "data-testid": "app-search-icon",
                    onClick: _ctx.clearInput
                  }, null, 8, ["name", "class", "onClick"]))
                : (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.appTableContentSearchIcon), {
                    key: 1,
                    size: "20px"
                  }))
            ]),
            key: "0"
          }
        : undefined,
      (_ctx.selectSearch)
        ? {
            name: "append",
            fn: _withCtx(() => [
              (_ctx.isFontIcon(_ctx.appTableContentSearchIcon))
                ? (_openBlock(), _createBlock(_component_AppIcon, {
                    key: 0,
                    name: String(_ctx.appTableContentSearchIcon),
                    size: "20px",
                    class: _normalizeClass([
            'app-search__icon',
            { 'app-search__icon--black': _ctx.isInputFocused },
          ]),
                    "data-testid": "app-search-icon-2",
                    onClick: _ctx.clearInput
                  }, null, 8, ["name", "class", "onClick"]))
                : (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.appTableContentSearchIcon), {
                    key: 1,
                    size: "20px"
                  }))
            ]),
            key: "1"
          }
        : undefined
    ]), 1032, ["modelValue", "placeholder", "onFocus", "onBlur"])
  ], 2))
}