import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6c468454"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "app-no-data" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppIcon = _resolveComponent("AppIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.icon)
      ? (_openBlock(), _createBlock(_component_AppIcon, {
          key: 0,
          name: _ctx.icon,
          class: "app-no-data__icon"
        }, null, 8, ["name"]))
      : _createCommentVNode("", true),
    _createElementVNode("span", null, _toDisplayString(_ctx.message || _ctx.$t('common.noSearchResuls')), 1)
  ]))
}