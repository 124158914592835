<template>
  <div
    :class="[
      'app-search',
      {
        'app-search__filled': !isFilterEmpty,
        'app-search--select': selectSearch,
      },
    ]"
  >
    <q-input
      v-model="model"
      borderless
      class="app-search__input"
      debounce="300"
      :placeholder="searchInputPlaceholder"
      data-testid="app-search"
      @focus="focusInput"
      @blur="blurInput"
    >
      <template v-if="!selectSearch" #prepend>
        <AppIcon
          v-if="isFontIcon(appTableContentSearchIcon)"
          :name="String(appTableContentSearchIcon)"
          size="20px"
          :class="[
            'app-search__icon',
            { 'app-search__icon--black': isInputFocused },
          ]"
          data-testid="app-search-icon"
          @click="clearInput"
        />
        <component :is="appTableContentSearchIcon" v-else size="20px" />
      </template>
      <template v-if="selectSearch" #append>
        <AppIcon
          v-if="isFontIcon(appTableContentSearchIcon)"
          :name="String(appTableContentSearchIcon)"
          size="20px"
          :class="[
            'app-search__icon',
            { 'app-search__icon--black': isInputFocused },
          ]"
          data-testid="app-search-icon-2"
          @click="clearInput"
        />
        <component :is="appTableContentSearchIcon" v-else size="20px" />
      </template>
    </q-input>
  </div>
</template>
<script lang="ts">
import { defineComponent, type Component } from 'vue';
import { roundClose } from '@quasar/extras/material-icons-round';
import { mdiMagnify } from '@quasar/extras/mdi-v6';
import AppIcon from '@/components/app/AppIcon/AppIcon.vue';
import IconMagnify from '@/assets/icons/icon-magnify.vue';

export default defineComponent({
  name: 'AppSearch',

  components: {
    AppIcon,
  },

  props: {
    modelValue: {
      type: [Object, String, Number],
      default: undefined,
    },

    selectSearch: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['update:modelValue'],

  data() {
    return {
      isInputFocused: false,
      roundClose,
      mdiMagnify,
    };
  },

  computed: {
    model: {
      get(): string {
        const newValue = this.modelValue ? this.modelValue.toString() : '';

        return newValue;
      },

      set(newValue: string | number) {
        const updatedValue = newValue || '';

        this.$emit('update:modelValue', updatedValue);
      },
    },

    isFilterEmpty(): boolean {
      return this.model.length === 0;
    },

    appTableContentSearchIcon(): string | Component {
      if (!this.isFilterEmpty) {
        return this.roundClose;
      }
      if (this.selectSearch) {
        return this.mdiMagnify;
      }
      if (!this.isFilterEmpty && this.selectSearch) {
        return this.roundClose;
      }
      return IconMagnify;
    },

    searchInputPlaceholder(): string {
      return this.isInputFocused ? '' : this.$t('common.search.placeholder');
    },
  },

  methods: {
    clearInput(): void {
      this.$emit('update:modelValue', '');
    },

    focusInput(): void {
      this.isInputFocused = true;
    },

    blurInput(): void {
      this.isInputFocused = false;
    },

    isFontIcon(icon: string | Component): boolean {
      return typeof icon === 'string';
    },
  },
});
</script>
<style lang="scss">
.app-search {
  display: flex;
  align-items: center;
  max-width: 200px;
  color: $blue-500;
  background: none;
  border-radius: 4px;
  border: 1px solid transparent;
}

.app-search--select {
  background-color: $gray-50;
  margin-bottom: 4px;
  border-radius: 4px;
  padding: 0px 4px 0px 8px;
  max-width: none;
}

.app-search:hover {
  background-color: $gray-50;
}

.app-search:focus-within,
.app-search.app-search__filled {
  border-color: $blue-500;
  color: $gray-800;
}

.app-search__icon {
  cursor: pointer;
  padding: 6px;
  color: $blue-500;
  background: $vaibe-gradient-dark;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.app-search__icon--black {
  color: $black;
}

.app-search__input :deep(input::placeholder) {
  color: $blue-500;
}

.app-search .q-field__control,
.app-search .q-field__marginal {
  height: 30px;
}

.app-search__input {
  flex-grow: 1;
}

.app-search .q-field__prepend {
  padding-left: 8px;
}
</style>
