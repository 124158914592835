<template>
  <AppButton dense :ripple="false" :icon="showClearIcon" class="clear-icon" />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import AppButton from '@/components/app/AppButton/AppButton.vue';

export default defineComponent({
  name: 'AppClearIcon',

  components: {
    AppButton,
  },

  props: {
    hasErrors: { type: Boolean, default: false },
  },

  computed: {
    showClearIcon(): string {
      return this.hasErrors
        ? 'img:/icons/clear-icon-error.svg'
        : 'img:/icons/clear-icon.svg';
    },
  },
});
</script>

<style scoped lang="scss">
.clear-icon {
  width: 24px;
  > :deep(.q-focus-helper) {
    display: none;
  }
}

:deep(.q-icon) {
  width: 14px;
}
</style>
