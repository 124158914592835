<script setup lang="ts">
import notify from '@/shared/helpers/notify/notify';
import {
  validateConfirmPassword,
  validateNewPassword,
} from '@/shared/helpers/password/changePasswordValidator/changePasswordValidator';
import { ResponseError } from '@/shared/types/utils';
import useProfileStore from '@/store/profile/useProfileStore';
import useUserStore from '@/store/user/useUserStore';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import AppButton from '../AppButton/AppButton.vue';
import AppLabel from '../AppLabel/AppLabel.vue';
import AppPasswordInput from '../AppPasswordInput/AppPasswordInput.vue';
import AppPasswordValidations from '../AppPasswordValidations/AppPasswordValidations.vue';

interface Props {
  successRoute?: string;
}

const props = withDefaults(defineProps<Props>(), {
  successRoute: '/account-settings/security',
});

const emit = defineEmits(['password-changed']);

const { t } = useI18n();

const router = useRouter();

const userStore = useUserStore();

const profileStore = useProfileStore();

const isLoading = ref<boolean>(false);

const newPasswordModel = ref<string>('');

const newPasswordConfirmationModel = ref<string>('');

const newPasswordError = ref<string>('');

const newPasswordConfirmationError = ref<string>('');

const validationsAreDisplayed = ref<boolean>(false);

function handleNewPasswordInputFocus(): void {
  validationsAreDisplayed.value = true;
}

function handleCancelClick(): void {
  router.back();
}

async function handleSaveClick(): Promise<void> {
  isLoading.value = false;

  newPasswordError.value = validateNewPassword(newPasswordModel.value);

  newPasswordConfirmationError.value = validateConfirmPassword(
    newPasswordModel.value,
    newPasswordConfirmationModel.value,
  );

  if (newPasswordError.value || newPasswordConfirmationError.value) {
    isLoading.value = false;

    return;
  }

  try {
    if (profileStore.userProfile?.user) {
      userStore.setUserEmail(profileStore.userProfile.user.email);
    }

    await userStore.changePassword(newPasswordModel.value);

    await router.push(props.successRoute);

    emit('password-changed');
  } catch (error) {
    if (error instanceof ResponseError) {
      notify(t(error.reason.message), 'danger');
    }
  } finally {
    isLoading.value = false;
  }
}
</script>

<template>
  <div class="app-change-password-form">
    <h1 class="app-change-password-form__title">
      <slot name="title">
        {{ t('changePassword.header.createPassword') }}
      </slot>
    </h1>
    <div class="app-change-password-form__field">
      <AppLabel :label="t('changePassword.newPassword')" for="new-password" />
      <AppPasswordInput
        v-model="newPasswordModel"
        name="new-password"
        data-testid="app-change-password-form-new-password-field"
        :error="newPasswordError"
        @focus="handleNewPasswordInputFocus"
      />
    </div>
    <AppPasswordValidations
      v-if="validationsAreDisplayed"
      class="app-change-password-form__validations"
      :password="newPasswordModel"
    />
    <div class="app-change-password-form__field">
      <AppLabel
        :label="t('changePassword.confirmPassword')"
        for="new-password-confirmation"
      />
      <AppPasswordInput
        v-model="newPasswordConfirmationModel"
        name="new-password-confirmation"
        :error="newPasswordConfirmationError"
      />
    </div>
    <div class="app-change-password-form__footer">
      <slot name="actions" :submit="handleSaveClick" :is-loading="isLoading">
        <AppButton
          type="empty"
          :loading="isLoading"
          :label="t('labels.common.cancel')"
          @click="handleCancelClick"
        />
        <AppButton
          type="bordered"
          :loading="isLoading"
          :label="t('changePassword.updatePassword')"
          @click="handleSaveClick"
        />
      </slot>
    </div>
  </div>
</template>

<style scoped lang="scss">
.app-change-password-form__title {
  margin-bottom: 24px;
}

.app-change-password-form__validations {
  margin-bottom: 16px;
}

.app-change-password-form__footer {
  gap: 24px;
  display: flex;
  justify-content: flex-end;
}
</style>
