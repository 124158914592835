import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppButton = _resolveComponent("AppButton")!

  return (_openBlock(), _createBlock(_component_AppButton, {
    dense: "",
    ripple: false,
    icon: 'img:' + _ctx.showPasswordIcon,
    class: "password-icon"
  }, null, 8, ["icon"]))
}