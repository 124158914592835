<script setup lang="ts">
interface Props {
  mini: boolean;
}

defineProps<Props>();

const APP_DRAWER_WIDTH = 257;

const APP_DRAWER_MINI_WIDTH = 64;

const model = defineModel<boolean>({ required: true });
</script>

<template>
  <q-drawer
    v-model="model"
    :mini="mini"
    :breakpoint="0"
    :width="APP_DRAWER_WIDTH"
    :mini-width="APP_DRAWER_MINI_WIDTH"
    show-if-above
  >
    <slot />
  </q-drawer>
</template>
