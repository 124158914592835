<script setup lang="ts">
import ProfileDrawerLink from '@/components/profile/ProfileDrawerLink/ProfileDrawerLink.vue';
import useDrawerLinks from '@/composables/useDrawerLinks/useDrawerLinks';
import OrganizationSettingsLink from '@/components/organizations/OrganizationSettingsLink/OrganizationSettingsLink.vue';
import AppDrawerLinkList from '../AppDrawerLinkList/AppDrawerLinkList.vue';
import AppVaibeLogo from '../AppVaibeLogo/AppVaibeLogo.vue';
import AppDrawer from '../AppDrawer/AppDrawer.vue';

interface Props {
  mini: boolean;
}

defineProps<Props>();

const model = defineModel<boolean>({ required: true });

const { drawerLinks } = useDrawerLinks();
</script>

<template>
  <AppDrawer
    v-model="model"
    :mini="mini"
    :class="['app-layout-drawer', { 'app-layout-drawer--mini': mini }]"
  >
    <AppVaibeLogo class="app-layout-drawer__logo" :is-small="mini" />
    <div class="app-layout-drawer__content">
      <AppDrawerLinkList
        class="app-layout-drawer__content__top"
        :mini="mini"
        :links="drawerLinks"
      />
      <div class="app-layout-drawer__content__bottom">
        <OrganizationSettingsLink :mini="mini" />
        <ProfileDrawerLink :mini="mini" />
      </div>
    </div>
  </AppDrawer>
</template>

<style scoped lang="scss">
:deep(.app-layout-drawer) {
  display: flex;
  padding: 24px 32px;
  background: $gray-50;
  flex-direction: column;
}

:deep(.app-layout-drawer.app-layout-drawer--mini) {
  padding: 24px 0;
}

.app-layout-drawer__logo {
  height: 64px;
  display: flex;
  margin-left: 20px;
  align-items: center;
  transition: all 0.3s;
  justify-content: flex-start;
}

.app-layout-drawer.app-layout-drawer--mini .app-layout-drawer__logo {
  margin-left: 16px;
}

.app-layout-drawer__content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.app-layout-drawer__content__bottom,
.app-layout-drawer__content__top {
  width: 100%;
}
</style>
