
import { defineComponent, PropType } from 'vue';
import AppButton from '@/components/app/AppButton/AppButton.vue';
import AppIcon from '@/components/app/AppIcon/AppIcon.vue';
import AppInput from '@/components/app/AppInput/AppInput.vue';
import {
  mdiArrowDownThick,
  mdiArrowLeftThick,
  mdiArrowRightThick,
  mdiArrowUpThick,
  mdiClose,
} from '@quasar/extras/mdi-v6';
import { BLUE_500 } from '@/shared/constants/colors';
import { Orientation } from '@/shared/types/utils';
import {
  AppListSelectorListInformation,
  AppListSelectorListSide,
  AppListSelectorOption,
} from '@/shared/types/components';

const __default__ = defineComponent({
  name: 'AppListSelector',

  components: { AppButton, AppIcon, AppInput },

  props: {
    left: {
      type: Array as PropType<AppListSelectorOption[]>,
      required: true,
    },
    right: {
      type: Array as PropType<AppListSelectorOption[]>,
      required: true,
    },
    optionLabelKey: {
      type: String as PropType<keyof AppListSelectorOption>,
      default: 'label',
    },
    optionValueKey: {
      type: String as PropType<keyof AppListSelectorOption>,
      default: 'value',
    },
    orientation: {
      type: String as PropType<Orientation>,
      default: Orientation.HORIZONTAL,
    },
    width: {
      type: String,
      default: 'unset',
    },
    height: {
      type: String,
      default: '256px',
    },
  },

  emits: ['update:left', 'update:right'],

  data() {
    return {
      selectedOptions: [] as AppListSelectorOption[],
      searchModels: {
        [AppListSelectorListSide.LEFT]: '',
        [AppListSelectorListSide.RIGHT]: '',
      },
      buttonsInformation: {
        [Orientation.HORIZONTAL]: {
          [AppListSelectorListSide.LEFT]: {
            label: 'labels.common.moveRight',
            icon: mdiArrowRightThick,
          },
          [AppListSelectorListSide.RIGHT]: {
            label: 'labels.common.moveLeft',
            icon: mdiArrowLeftThick,
          },
        },
        [Orientation.VERTICAL]: {
          [AppListSelectorListSide.LEFT]: {
            label: 'labels.common.moveDown',
            icon: mdiArrowDownThick,
          },
          [AppListSelectorListSide.RIGHT]: {
            label: 'labels.common.moveUp',
            icon: mdiArrowUpThick,
          },
        },
      },
      mdiClose,
      BLUE_500,
      AppListSelectorListSide,
    };
  },

  computed: {
    leftListInformation(): AppListSelectorListInformation {
      return {
        options: this.left
          .filter((option) =>
            `${option[this.optionLabelKey]}`
              .toLowerCase()
              .includes(this.searchModels.left.toString().toLowerCase()),
          )
          .sort((a, b) =>
            `${a[this.optionLabelKey]}`.localeCompare(
              `${b[this.optionLabelKey]}`,
            ),
          ),
        name: AppListSelectorListSide.LEFT,
      };
    },

    rightListInformation(): AppListSelectorListInformation {
      return {
        options: this.right
          .filter((option) =>
            `${option[this.optionLabelKey]}`
              .toLowerCase()
              .includes(this.searchModels.right.toString().toLowerCase()),
          )
          .sort((a, b) =>
            `${a[this.optionLabelKey]}`.localeCompare(
              `${b[this.optionLabelKey]}`,
            ),
          ),

        name: AppListSelectorListSide.RIGHT,
      };
    },

    selectedOptionValuesToOptionsMap(): Record<string, AppListSelectorOption> {
      return this.selectedOptions.reduce(
        (
          acc: Record<string, AppListSelectorOption>,
          option: AppListSelectorOption,
        ) => {
          acc[`${option[this.optionValueKey]}`] = option;

          return acc;
        },
        {},
      );
    },

    mainComponentFlexDirection(): 'row' | 'column' {
      if (this.orientation === Orientation.HORIZONTAL) {
        return 'row';
      }

      return 'column';
    },

    buttonsFlexDirection(): 'row' | 'column' {
      if (this.orientation === Orientation.HORIZONTAL) {
        return 'column';
      }

      return 'row';
    },

    mainComponentAlignment(): 'center' | 'left' {
      if (this.orientation === Orientation.HORIZONTAL) {
        return 'center';
      }

      return 'left';
    },
  },

  methods: {
    handleOptionClick(option: AppListSelectorOption, multiple = false): void {
      if (
        this.selectedOptionValuesToOptionsMap[`${option[this.optionValueKey]}`]
      ) {
        this.selectedOptions = this.selectedOptions.filter(
          (o) => o[this.optionValueKey] !== option[this.optionValueKey],
        );
      } else if (multiple) {
        this.selectedOptions.push(option);
      } else {
        this.selectedOptions = [option];
      }
    },

    moveSelectedOptions(
      from: AppListSelectorListSide,
      to: AppListSelectorListSide,
    ): void {
      const alreadyMovedOptionValues = this[to].map(
        (option) => option[this.optionValueKey],
      );

      const optionsToMove = this.selectedOptions.filter(
        (option) =>
          !alreadyMovedOptionValues.includes(option[this.optionValueKey]),
      );

      const optionsToMoveValues = optionsToMove.map(
        (option) => option[this.optionValueKey],
      );

      this.$emit(
        `update:${from}`,
        this[from].filter(
          (option) =>
            !optionsToMoveValues.includes(option[this.optionValueKey]),
        ),
      );

      this.$emit(`update:${to}`, [...this[to], ...optionsToMove]);

      this.selectedOptions = [];
    },
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "dbd09edc": (_ctx.mainComponentAlignment),
  "bcf9a296": (_ctx.mainComponentFlexDirection),
  "0923605a": (_ctx.width),
  "588cb0ba": (_ctx.height),
  "09bd5bd8": (_ctx.buttonsFlexDirection)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__