<template>
  <div v-if="error" class="app-error-message" data-testid="app-error-message">
    <img :src="errorIcon" alt="Error" class="app-error-message__icon" />
    <span class="app-error-message__text">{{ error }}</span>
  </div>
</template>

<script lang="ts">
import { errorIcon } from '@/shared/constants/icons';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AppErrorMessage',

  props: {
    error: {
      type: String,
      default: undefined,
    },
  },

  data() {
    return { errorIcon };
  },
});
</script>

<style scoped lang="scss">
.app-error-message {
  gap: 6px;
  display: flex;
  align-items: center;
}

.app-error-message__icon {
  padding: 4px;
}

.app-error-message__text {
  color: $red-500;
  font-size: 12px;
  font-weight: 400;
  line-height: 150%;
}
</style>
