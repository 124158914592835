<template>
  <div class="app-badge">
    <slot />
  </div>
</template>

<script lang="ts">
import { GRAY_800 } from '@/shared/constants/colors';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AppBadge',

  props: {
    color: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      default: '90px',
    },
    height: {
      type: String,
      default: '18px',
    },
    padding: {
      type: String,
      default: 'unset',
    },
    textColor: {
      type: String,
      default: GRAY_800,
    },
  },
});
</script>

<style scoped lang="scss">
.app-badge {
  height: v-bind(height);
  font-size: 12px;
  text-wrap: nowrap;
  text-align: center;
  border-radius: 4px;
  width: v-bind(width);
  padding: v-bind(padding);
  color: v-bind(textColor);
  background-color: v-bind(color);
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
