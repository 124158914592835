<template>
  <q-btn-dropdown
    class="app-button-dropdown"
    :class="[
      `app-button-dropdown--${type}`,
      {
        'app-button-dropdown--no-bg': noBackground,
        'app-button-dropdown--not-rounded': !rounded,
      },
    ]"
    content-class="app-button-dropdown__menu"
    v-bind="$props"
    unelevated
    no-caps
    rounded
    :ripple="false"
    :dropdown-icon="dropdownIcon"
    data-testid="app-button-dropdown"
  >
    <slot />
    <template #label>
      <div class="app-button-dropdown__label">
        <slot name="label" />
      </div>
    </template>
  </q-btn-dropdown>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { symRoundedKeyboardArrowDown } from '@quasar/extras/material-symbols-rounded';
import { AppButtonDropdownType } from '@/shared/types/components';

export default defineComponent({
  name: 'AppButtonDropdown',
  props: {
    type: {
      type: String as PropType<AppButtonDropdownType>,
      default: 'default',
    },
    dropdownIcon: {
      type: String,
      default: symRoundedKeyboardArrowDown,
    },
    noBackground: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      symRoundedKeyboardArrowDown,
    };
  },
});
</script>

<style scoped lang="scss">
.app-button-dropdown {
  background-color: $blue-500;
  color: $white;
}

.app-button-dropdown > :deep(.q-btn):first-child {
  padding: 6px 16px;
  justify-content: center;
  min-height: unset;
}

.app-button-dropdown > :deep(.q-btn):nth-child(2) {
  padding: 0 8px 0 0;
  justify-content: center;
  min-height: unset;
}

.app-button-dropdown > :deep(.q-btn):first-child .q-btn__content {
  gap: 8px;
  padding-left: 8px;
  flex: 0;
}

.app-button-dropdown > :deep(.q-btn):nth-child(2) .q-btn__content {
  flex: 0;
}

.app-button-dropdown
  > :deep(.q-btn):first-child
  .q-btn__content
  > *:nth-child(2) {
  line-height: 21px;
  font-size: 14px;
  font-weight: 700;
}

.app-button-dropdown > :deep(.q-btn):first-child .q-btn__content .q-icon {
  margin: 0;
  height: 14px;
  width: 14px;
  display: flex;
}

.app-button-dropdown > :deep(.q-btn):nth-child(2) .q-btn__content .q-icon {
  font-size: 18px;
  padding: 7px;
}

.app-button-dropdown :deep(.q-btn-dropdown__arrow:empty) {
  display: none;
}

.app-button-dropdown--not-rounded {
  border-radius: 8px;
}

.app-button-dropdown--no-bg {
  background: transparent;
}

.app-button-dropdown--no-bg :deep(.q-focus-helper) {
  display: none;
}

//

.app-button-dropdown--add {
  padding: 9px;
  color: $white;
  border-radius: 100px;
  background: $vaibe-gradient-dark;
  box-shadow: $special-shadow;
  font-weight: 700;
}

.app-button-dropdown--add:hover {
  background: $blue-500;
}

.app-button-dropdown--add :deep(.q-focus-helper) {
  display: none;
}

.app-button-dropdown--add > :deep(.q-btn):first-child:focus-visible {
  background: $vaibe-gradient-dark;
  border: 1px solid $blue-300;
}

.app-button-dropdown--add > :deep(.q-btn):nth-child(2):focus-visible {
  background: $vaibe-gradient-dark;
  border: 1px solid $blue-300;
}

.app-button-dropdown--add :deep(.q-icon) {
  height: 24px;
  width: 24px;
  display: flex;
  margin: 0;
}

.app-button-dropdown--transparent {
  display: flex;
  align-items: center;
  background-color: transparent;
  height: 32px;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: $blue-500;
}

.app-button-dropdown--transparent:hover {
  background: transparent;
}

.app-button-dropdown--transparent :deep(.q-icon) {
  font-size: 16px;
  margin-right: 8px;
}

.app-button-dropdown__label {
  display: flex;
  gap: 8px;
}
</style>
