<script setup lang="ts">
import AppButton from '../AppButton/AppButton.vue';

interface Props {
  formId?: string;
  isLoading: boolean;
}

interface Emits {
  (e: 'save'): void;
  (e: 'cancel'): void;
}

defineProps<Props>();

const emit = defineEmits<Emits>();
</script>

<template>
  <div class="app-form-footer">
    <AppButton
      type="empty"
      size="M"
      data-testid="cancel-button"
      :label="$t('labels.common.cancel')"
      @click="emit('cancel')"
    />
    <AppButton
      type="vaibe"
      native-type="submit"
      size="M"
      data-testid="save-button"
      :form="formId"
      :loading="isLoading"
      :label="$t('labels.common.save')"
      @click="emit('save')"
    />
  </div>
</template>

<style scoped lang="scss">
.app-form-footer {
  flex-grow: 0;
  gap: 24px;
  display: flex;
  padding: 24px 88px;
  flex-direction: row;
  justify-content: flex-end;
  box-shadow: 0px -4px 16px rgba(0, 0, 0, 0.06);
}
</style>
