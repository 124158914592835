<script setup lang="ts">
import {
  onMounted,
  onUnmounted,
  shallowRef,
  ComponentPublicInstance,
} from 'vue';
import { SidebarActionList } from '@/shared/types/components';
import AppSidebarHeader from './AppSidebarHeader/AppSidebarHeader.vue';

interface Props {
  sidebarTitle: string;
  actions?: SidebarActionList;
  editMode: boolean;
}

interface Emit {
  (e: 'clickOutside'): void;
}

const appSidebarOpen = defineModel<boolean>();

const appSidebar = shallowRef<ComponentPublicInstance | null>(null);

withDefaults(defineProps<Props>(), {
  actions: undefined,
  editMode: false,
});

const emit = defineEmits<Emit>();

const handleMouseDown = (event: MouseEvent) => {
  if (appSidebarOpen.value) {
    const target = event.target as HTMLElement;

    const appSidebarElement = appSidebar.value?.$el;

    const documentPositionComparison =
      target.compareDocumentPosition(appSidebarElement);

    if (
      documentPositionComparison !== Node.DOCUMENT_POSITION_PRECEDING &&
      documentPositionComparison !== Node.DOCUMENT_TYPE_NODE
    ) {
      emit('clickOutside');
    }
  }
};

const closeSidebar = () => {
  appSidebarOpen.value = false;
};

onMounted(() => {
  window.addEventListener('mousedown', handleMouseDown);
});

onUnmounted(() => {
  window.removeEventListener('mousedown', handleMouseDown);
});
</script>

<template>
  <q-drawer
    ref="appSidebar"
    v-bind="$props"
    v-model="appSidebarOpen"
    side="right"
    class="app-sidebar"
    overlay
    data-testid="app-sidebar-drawer"
    behavior="desktop"
    persistent
    :width="568"
  >
    <AppSidebarHeader
      :title="sidebarTitle"
      :edit-mode="editMode"
      :actions="actions"
      @close="closeSidebar"
    />
    <div class="app-sidebar__content">
      <slot />
    </div>
  </q-drawer>
</template>

<style scoped lang="scss">
:deep(.q-drawer__backdrop) {
  opacity: 0;
}

:deep(.app-sidebar) {
  background-color: $white;
  display: flex;
  flex-direction: column;
  box-shadow: -1px 0px 20px rgba(0, 0, 0, 0.15);
  border-radius: 16px 0 0 0;
  position: relative;
}

:deep(.q-drawer) {
  top: 0px;
}

.app-sidebar__content {
  overflow-y: auto;
  flex: 1;
}
</style>
