<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { DrawerLink } from '@/shared/types/components';
import AppDrawerLink from '../AppDrawerLink/AppDrawerLink.vue';

interface Props {
  mini: boolean;
  links: DrawerLink[];
}

defineProps<Props>();

const { t } = useI18n();
</script>

<template>
  <nav class="app-drawer-link-list">
    <AppDrawerLink
      v-for="(link, i) in links"
      :key="i"
      :mini="mini"
      :to="link.to"
      :label="t(link.label)"
    >
      <template #leading>
        <component :is="link.icon" />
      </template>
    </AppDrawerLink>
  </nav>
</template>

<style scoped lang="scss">
:deep(.app-drawer-link--active .app-drawer-link__leading) svg path {
  fill: $blue-500;
}
</style>
