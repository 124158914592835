<script setup lang="ts">
import { computed, getCurrentInstance } from 'vue';
import IconClear from '@/assets/icons/icon-clear.vue';
import { BLUE_500 } from '@/shared/constants/colors';
import { SidebarActionList } from '@/shared/types/components';
import { useI18n } from 'vue-i18n';
import { BaseTooltip } from '@/plugins/commons';
import AppSeparator from '../../AppSeparator/AppSeparator.vue';
import AppButton from '../../AppButton/AppButton.vue';
import AppSidebarActions from '../../AppSidebarActions/AppSidebarActions.vue';

interface Props {
  title: string;
  actions?: SidebarActionList;
  editMode: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  title: '',
  actions: undefined,
  editMode: true,
});

interface Emits {
  (e: 'close'): void;
}

const emit = defineEmits<Emits>();

const { t } = useI18n();

const hasCloseEmit = computed<boolean>(
  () => !!getCurrentInstance()?.vnode.props?.onClose,
);

const title = computed<string>(() => {
  if (props.editMode) {
    return t('sidebars.title.edit', [props.title]);
  }

  return props.title;
});

defineExpose({
  hasCloseEmit,
});
</script>
<template>
  <div class="app-sidebar-header">
    <h3 class="app-sidebar-header__title">
      <b>
        {{ title }}
      </b>
    </h3>
    <template v-if="!editMode">
      <div v-if="actions" class="app-sidebar-header__actions">
        <AppSidebarActions :list="actions" />
      </div>
      <div v-if="hasCloseEmit" class="app-sidebar-header__close">
        <AppSeparator vertical inset />
        <AppButton
          class="app-sidebar-header__close__button"
          type="icon"
          @click="emit('close')"
        >
          <IconClear width="16" height="16" :color="BLUE_500" />
          <BaseTooltip
            anchor="top middle"
            self="bottom middle"
            :offset="[5, 5]"
          >
            {{ t('common.close') }}
          </BaseTooltip>
        </AppButton>
      </div>
    </template>
  </div>
</template>
<style scoped lang="scss">
.app-sidebar-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  padding: 24px 32px 24px 32px;
}

.app-sidebar-header__title {
  font-size: 18px;
  margin: 0;
  flex: 1;
}

.app-sidebar-header__actions {
  display: flex;
  gap: 16px;
}

.app-sidebar-header__close {
  display: flex;
  gap: 16px;
}

.app-sidebar-header__close__button {
  padding: 8px;
}

.app-sidebar-header__close__button :deep(i) {
  font-size: 16px;
  color: $blue-500;
}
</style>
