<script setup lang="ts">
import { computed, useSlots } from 'vue';
import { useI18n } from 'vue-i18n';
import { RouteRecordName, useRoute } from 'vue-router';
import AppIcon from '@/components/app/AppIcon/AppIcon.vue';

const { t } = useI18n();

const route = useRoute();

const slots = useSlots();

const matchedRouteNames = computed<(RouteRecordName | undefined)[]>(() =>
  route.matched
    .filter((match) => !match.meta.default)
    .map((match) => match.name),
);

function isNotLastRoute(routeName?: RouteRecordName) {
  return (
    routeName !== matchedRouteNames.value[matchedRouteNames.value.length - 1]
  );
}
</script>

<template>
  <div class="app-breadcrumbs">
    <template v-for="(routeName, index) in matchedRouteNames" :key="index">
      <AppIcon
        v-if="index > 0"
        class="app-breadcrumbs__separator"
        name="chevron_right"
        size="18px"
      />
      <h1
        :class="[
          'app-breadcrumbs__item',
          { 'app-breadcrumbs__item--last-route': isNotLastRoute(routeName) },
        ]"
        data-testid="organizations-page-header"
      >
        {{ t(`nav.${routeName?.toString()}`) }}
      </h1>
    </template>
    <div v-if="slots.trailing" class="app-breadcrumbs__trailing">
      <slot name="trailing" />
    </div>
  </div>
</template>

<style scoped lang="scss">
.app-breadcrumbs {
  flex-grow: 0;
  display: flex;
  padding: 32px;
  position: relative;
  align-items: center;
}

.app-breadcrumbs__separator {
  width: 32px;
  display: flex;
  color: $gray-800;
  align-self: center;
}

.app-breadcrumbs__item--last-route {
  color: $blue-500;
}

.app-breadcrumbs__trailing {
  right: 32px;
  position: absolute;
}
</style>
